import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import ImageZoom from 'react-image-magnifier-zoom';

const CustomSlide = ({ slide }) => (
  <div>
    <ImageZoom
        src={slide.src}
        width={400}
        height={400}
        magnifierSize={100}
        zoomLevel={2.5}
        enabled={true}
      />
      {/* <Magnifier src={slide.src} width={500} />; */}
  </div>
);

const GalleryLightBox = ({ open, setOpen, images }) => {
  return (
    <>
      <Lightbox
        slides={images}
        open={open}
        index={0}
        close={() => setOpen(false)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
        // zoom={{
        //   maxZoomPixelRatio: 3, // Set maximum zoom level
        //   scrollToZoom: true, // Enable scroll-based zoom
        //   wheelZoomDistanceFactor: 100, // Speed of zoom
        //   doubleClickZoom: 2, // Double-click zoom level
        //   pinchZoom: true, // Enable pinch zoom for touch devices
        // }}
        render={{ slide: CustomSlide }}
      />
    </>
  );
};

export default GalleryLightBox;
