import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../Utils";
import "./Map.css";
import MapContext from "./MapContext";
import * as ol from "ol";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import { fromLonLat, get } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction/defaults";
import DragRotate from "ol/interaction/DragRotate";
import { platformModifierKeyOnly } from "ol/events/condition";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import GeoJSON from "ol/format/GeoJSON";
import LayerLegend from "./LayerLegend";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import Overlay from "ol/Overlay";
import { Cluster, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import GalleryLightBox from "../Lightbox";

import "ol/ol.css";
const Map = ({ children }) => {
  const appSettingsState = useSelector((state) => state.AppSettings);
  const selectedMode = useSelector((state) => state.AppSettings.selectedMode);
  const layerMode = useSelector((state) => state.AppSettings.layerMode);
  const action = useSelector((state) => state.AppSettings.action);
  const zoom_ = useSelector((state) => state.AppSettings.zoom);
  const fieldsState = useSelector((state) => state.Fields);
  let singleData = useSelector((state) => state.Fields.locationsData.single);
  let dualData = useSelector((state) => state.Fields.locationsData.dual);
  let plantImages = useSelector((state) => state.Fields.plantImages);
  
  const mapRef = useRef();
  const photoMode = useRef(false);
  const singlePlantImages = useRef({});
  const dualPlantImages = useRef({});
  const [map, setMap] = useState(null);
  const [photoOpen, setPhotoOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const mapRef2 = useRef();
  const [map2, setMap2] = useState(null);
  let disptach = useDispatch();
  //childLayerLegend2 = React.createRef();
  //childLayerLegend = React.createRef();

  let layerLegendColorMin2 = 0;
  let layerLegendColorMax2 = 240;
  let vector1 = null;
  let vector2 = null;

  useEffect(() => {
    photoMode.current = appSettingsState.photoMode;
  }, [appSettingsState.photoMode])

  useEffect(() => {
    singlePlantImages.current = plantImages.single;
    dualPlantImages.current = plantImages.dual;
  }, [plantImages])

  const getVector = (layerName, feats, attrType, selectedData, plantImages) => {
    let features = [];
    for (let i = 0; i < feats.length; ++i) {
      if (!feats[i].latLng) {
        continue;
      }
      let feature_ = new Feature(
        new Point(
          fromLonLat([
            feats[i].latLng.coordinates[1],
            feats[i].latLng.coordinates[0],
          ])
        )
      );
      feature_.set("f_id", parseInt(feats[i].id));
      feature_.set(Constants.SUM, parseFloat(feats[i].sum));
      feature_.set(Constants.AVG, parseFloat(feats[i].avg).toFixed(1));
      feature_.set(Constants.RATE, parseFloat(feats[i].changeRate));
      feature_.set("title", feats[i].title);
      feature_.set("location_no", feats[i].location_no);
      feature_.set("selected", false);
      feature_.set("selected_index", -1);
      if (
        selectedData &&
        selectedData.filter((_) => _.id == feats[i].location_no).length > 0
      ) {
        feature_.set("selected", true);
        feature_.set(
          "selected_index",
          selectedData.filter((_) => _.id == feats[i].location_no)[0].index
        );
      }
      features.push(feature_);
    }

    let source = new VectorSource({
      features: features,
    });
    /*
    let clusterSource = new Cluster({
      distance: 1,
      minDistance: 1,
      source: source,
    });
    */
    const attrType_ = attrType;
    let clusters = new VectorLayer({
      source: source,
      style: function (feature) {
        let textSum_ = feature.get(attrType_);
        let isSelected = feature.get("selected");
        let color = feature.get("color");
        if (attrType_ === Constants.RATE) {
          return new Style({
            text: new Text({
              text: feature.get(attrType_) < 0 ? "⇩" : "⇧",
              fill: new Fill({
                color: color,
              }),
              font: "bold 30px/1 Arial",
              stroke: new Stroke({
                color: "#000000",
                width: isSelected ? 5 : 2,
              }),
            }),
          });
        }
        return new Style({
          image: new CircleStyle({
            radius: 14,
            stroke: new Stroke({
              color: isSelected ? "#000000" : color ? color : "#f50f41",
              width: isSelected ? 3 : 1,
            }),
            fill: new Fill({
              color: color ? color : "#f50f41",
            }),
          }),
          text: new Text({
            text: textSum_ + "",
            fill: new Fill({
              color: plantImages[feature.get("location_no")] ? "#A9A9A9" : "#000",
            }),
            font: "bold 12px/1 Arial",
            stroke: new Stroke({
              color: color ? color : "#000",
              width: 0.1,
            }),
          }),
        });
      },
    });
    clusters.set("layer-name", layerName);
    return clusters;
  };

  const getFieldBoudaryVector = (layerName) => {
    return new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: "#f0f8ff26",
        }),
        stroke: new Stroke({
          color: "blue",
          width: 5,
        }),
      }),
      "layer-name": layerName,
      visible: true,
    });
  };

  const removeMapLayerByLayerName = (map_, layerName) => {
    let layers = map_.getLayers().getArray();
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].get("layer-name") == layerName) {
        map_.removeLayer(layers[i]);
      }
    }
  };
  const updateLayers = (updateExtent) => {
    let data = fieldsState.locationsData;
    let selectedData = fieldsState.selectedData;
    if (map2) {
      removeMapLayerByLayerName(map2, "vector2");
      vector2 = getVector("vector2", data.single, action, selectedData.single, singlePlantImages.current);
      map2.addLayer(vector2);
      if (updateExtent) {
        if (data.single?.length > 0) {
          if (vector2.getSource().getExtent().indexOf(Infinity) == -1) {
            var extent = vector2.getSource().getExtent();
            var X = extent[0] + (extent[2] - extent[0]) / 2;
            var Y = extent[1] + (extent[3] - extent[1]) / 2;
            map2.getView().animate({
              center: [X, Y],
              duration: 2000,
            });
            setTimeout(() => {
              map2.getView().fit(vector2.getSource().getExtent(), {
                size: map2.getSize(),
                maxZoom: 20,
              });
            }, 2000);
            /*map2.getView().fit(vector2.getSource().getExtent(), {
              size: map2.getSize(),
              maxZoom: 20
            });*/
            map2.updateSize();
          }
        }
      }
    }

    if (selectedMode == "Dual") {
      if (map) {
        map.setView(map2.getView());
        map.updateSize();

        removeMapLayerByLayerName(map, "vector1");
        vector1 = getVector("vector1", data.dual, action, selectedData.dual, dualPlantImages.current);
        map.addLayer(vector1);
      }
    }
  };

  const onMoveEnd = (evt) => {
    const map = evt.map;
    const extent = map.getView().calculateExtent(map.getSize());
    localStorage.setItem("current-map-extent", JSON.stringify(extent));
    localStorage.setItem("current-map-size", JSON.stringify(evt.map.getSize()));
    localStorage.setItem(
      "current-map-zoom",
      JSON.stringify(evt.map.getView().getZoom())
    );
    localStorage.setItem(
      "current-map-rotation",
      JSON.stringify(evt.map.getView().getRotation())
    );
  };

  const onMouseOverEvent = (evt) => {
    let coord_ = evt.coordinate;
    let ovl = evt.map.getOverlays().getArray()[0];
    ovl.setPosition(undefined);
    evt.map.forEachFeatureAtPixel(evt.pixel, function (f) {
      if (ovl.getElement()) {
        ovl.getElement().innerHTML = f.get("location_no")
          ? `<p>Loc.No:  ${f.get("location_no")}</p>`
          : `<p>Field:<br/>${f.get("title")}</p>`;
        ovl.setPosition(coord_);
        return true;
      }
    });
  };

  useEffect(() => {
    let zoom = 6;
    let center = fromLonLat([35.3301013, 38.7233174]);
    let view = new ol.View({ zoom, center });
    let vectorBoundary1 = getFieldBoudaryVector("vector-boundary");
    let vectorBoundary2 = getFieldBoudaryVector("vector-boundary");

    fieldsState.fields.map((field_, index) => {
      if (field_.polygon_data && field_.polygon_data.length > 0) {
        let geom_ = new GeoJSON().readGeometry(field_.polygon_data[0].geom);
        geom_.transform("EPSG:4326", "EPSG:3857");
        let feat_ = new Feature(geom_);
        feat_.set("title", field_.title);
        //feat_.set('name',)
        vectorBoundary1.getSource().addFeature(feat_);
        vectorBoundary2.getSource().addFeature(feat_);
      }
    });
    let options2 = {
      view: view,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
            maxZoom: 20,
          }),
        }),
      ],
      controls: defaultControls(),
      overlays: [
        new Overlay({
          // @ts-ignore
          element: document.getElementById("popup-2"),
          autoPan: false,
          autoPanAnimation: {
            duration: 250,
          },
        }),
      ],
      interactions: defaultInteractions({ altShiftDragRotate: false }).extend([
        new DragRotate({ condition: platformModifierKeyOnly }),
      ]),
    };
    let mapObject2 = new ol.Map(options2);
    mapObject2.setTarget(mapRef2.current);
    vectorBoundary2.setVisible(true);
    mapObject2.addLayer(vectorBoundary2);
    setMap2(mapObject2);
    mapObject2.on("moveend", onMoveEnd);
    mapObject2.on("pointermove", onMouseOverEvent);
    let options = {
      view: view,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      controls: defaultControls(),
      interactions: defaultInteractions({ altShiftDragRotate: false }).extend([
        new DragRotate({ condition: platformModifierKeyOnly }),
      ]),
      overlays: [
        new Overlay({
          // @ts-ignore
          element: document.getElementById("popup-1"),
          autoPan: false,
          autoPanAnimation: {
            duration: 250,
          },
        }),
      ],
    };
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    vectorBoundary1.setVisible(true);
    mapObject.addLayer(vectorBoundary1);
    mapObject.on("singleclick", function (evt) {
      evt.map.set("clicked", 1);
      evt.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        if(photoMode.current && feature.get("location_no")){
          setSelectedImages(dualPlantImages.current[feature.get("location_no")] ?? []);
          if(dualPlantImages.current[feature.get("location_no")]){
            setPhotoOpen(true);
          }
        }
        disptach.Fields.AddSelectedData({
          type: "dual",
          data: {
            id: feature.get("location_no"),
            index: dualData.findIndex(
              (a) => a.location_no === feature.get("location_no")
            ),
          },
        });
        feature.set("selected", !feature.get("selected"));
      });
    });
    mapObject.on("pointermove", onMouseOverEvent);
    mapObject2.on("singleclick", function (evt) {
      evt.map.set("clicked", evt.map);
      evt.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        if(photoMode.current && feature.get("location_no")){
          setSelectedImages(singlePlantImages.current[feature.get("location_no")] ?? []);
          if(singlePlantImages.current[feature.get("location_no")]){
            setPhotoOpen(true);
          }
        }
        disptach.Fields.AddSelectedData({
          type: "single",
          data: {
            id: feature.get("location_no"),
            index: singleData.findIndex(
              (a) => a.location_no === feature.get("location_no")
            ),
          },
        });
        feature.set("selected", !feature.get("selected"));
      });
    });

    setMap(mapObject);

    if (
      localStorage.getItem("current-map-extent") &&
      localStorage.getItem("current-map-size") &&
      localStorage.getItem("current-map-zoom") &&
      localStorage.getItem("current-map-rotation")
    ) {
      let extent = JSON.parse(localStorage.getItem("current-map-extent"));
      let size = JSON.parse(localStorage.getItem("current-map-size"));
      let zoom = JSON.parse(localStorage.getItem("current-map-zoom"));
      let rotation = localStorage.getItem("current-map-rotation");

      mapObject2.getView().fit(extent, size);
      mapObject2.getView().setZoom(zoom);
      mapObject2.getView().setRotation(parseFloat(rotation));
    }
    if (
      fieldsState.locationsData.single?.length > 0 ||
      fieldsState.locationsData.dual?.length > 0
    ) {
      let selectedData = fieldsState.selectedData;
      vector2 = getVector(
        "vector2",
        fieldsState.locationsData.single,
        action,
        selectedData.single,
        singlePlantImages.current
      );
      mapObject2.addLayer(vector2);
      if (selectedMode.toLowerCase() == "dual") {
        vector1 = getVector(
          "vector1",
          fieldsState.locationsData.dual,
          action,
          selectedData.dual,
          dualPlantImages.current
        );
        mapObject.addLayer(vector1);
      }
    }
    return () => {
      mapObject.setTarget(undefined);
      mapObject2.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf("/maps") > -1 && map2) {
      let layers = map2.getLayers().getArray();
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].get("layer-name") == "vector2") {
          if (layers[i].getSource().getExtent().indexOf(Infinity) == -1) {
            map2.getView().fit(layers[i].getSource().getExtent(), {
              size: map2.getSize(),
              maxZoom: 22,
            });
            map2.updateSize();
          }
        }
      }
    }
  }, [zoom_]);

  const [colored, setColored] = useState(false);

  useEffect(() => {
    if (map2 && !colored) {
      updateLayerLegend();
      setColored(true);
    }
  }, [map2]);

  const updateLayerLegend = () => {
    //this.childLayerLegend2.current.updateColors(action);
    let layers = map2.getLayers().getArray();
    let vect2_ = null;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].get("layer-name") == "vector2") {
        vect2_ = layers[i];
      }
    }
    let colors = [
      "rgba(255,0,0,1)",
      "rgba(255,145,0,1)",
      "rgba(255,252,0,1)",
      "rgba(0,230,131,1)",
      "rgba(1,255,201,1)",
      "rgba(126,233,255,1)",
      "rgba(221, 246, 252, 1)",
    ]; // rgba(252,252,252,1) 100%);
    colors = colors.reverse();
    if (vect2_) {
      let feats_ = vect2_.getSource().getFeatures();
      let values_ = [];
      let ranges_ = [];
      feats_.map(function (feature_) {
        values_.push(feature_.get(action));
      });
      layerLegendColorMax2 = Math.max(...values_);
      layerLegendColorMin2 = Math.min(...values_);
      let inc_ = (layerLegendColorMax2 - layerLegendColorMin2) / 7;
      for (let i = 0; i < 7; i++) {
        if (i == 0) {
          ranges_.push([layerLegendColorMin2, layerLegendColorMin2 + inc_]);
        } else if (i == 6) {
          ranges_.push([layerLegendColorMax2 - inc_, layerLegendColorMax2]);
        } else {
          ranges_.push([
            layerLegendColorMin2 + inc_ * i,
            layerLegendColorMin2 + inc_ * (i + 1),
          ]);
        }
      }
      feats_.map(function (feature_) {
        let val_ = feature_.get(action);
        if (action == Constants.RATE) {
          feature_.set("color", getArrowColor(val_));
        } else {
          for (let i = 0; i < ranges_.length; i++) {
            if (val_ >= ranges_[i][0] && val_ < ranges_[i][1]) {
              feature_.set("color", colors[i]);
            }
          }
        }
      });
      map2.updateSize({});
    }

    let layers1 = map.getLayers().getArray();
    let vect1_ = null;
    for (let i = 0; i < layers1.length; i++) {
      if (layers1[i].get("layer-name") == "vector1") {
        vect1_ = layers1[i];
      }
    }
    if (vect1_) {
      let feats_ = vect1_.getSource().getFeatures();
      let values_ = [];
      let ranges_ = [];
      feats_.map(function (feature_) {
        values_.push(feature_.get(action));
      });
      layerLegendColorMax2 = Math.max(...values_);
      layerLegendColorMin2 = Math.min(...values_);
      let inc_ = (layerLegendColorMax2 - layerLegendColorMin2) / 7;
      for (let i = 0; i < 7; i++) {
        if (i == 0) {
          ranges_.push([layerLegendColorMin2, layerLegendColorMin2 + inc_]);
        } else if (i == 6) {
          ranges_.push([layerLegendColorMax2 - inc_, layerLegendColorMax2]);
        } else {
          ranges_.push([
            layerLegendColorMin2 + inc_ * i,
            layerLegendColorMin2 + inc_ * (i + 1),
          ]);
        }
      }
      feats_.map(function (feature_) {
        let val_ = feature_.get(action);
        if (action == Constants.RATE) {
          feature_.set("color", getArrowColor(val_));
        } else {
          for (let i = 0; i < ranges_.length; i++) {
            if (val_ >= ranges_[i][0] && val_ < ranges_[i][1]) {
              feature_.set("color", colors[i]);
            }
          }
        }
      });
      map.updateSize({});
    }
  };

  const getArrowColor = (val_) => {
    if (!isFinite(val_) || val_ > 200) {
      return "rgba(255,0,0,1)"; //red
    } else if (val_ > 100 && val_ <= 200) {
      return "rgba(255,145,0,1)"; //orange
    } else if (val_ > 20 && val_ <= 100) {
      return "rgba(255,252,0,1)"; //yellow
    } else if (val_ > -20 && val_ <= 20) {
      return "rgba(0,230,131,1)"; //green
    } else if (val_ <= -20 && val_ > -100) {
      return "rgba(1,255,201,1)"; //light blue
    } else if (val_ <= -100 && val_ >= -200) {
      return "rgba(126,233,255,1)"; // light sky
    } else if (val_ < -200) {
      return "rgba(221, 246, 252, 1)"; //lightest sky
    }

    return "rgba(0,0,0,1)";
  };

  useEffect(() => {
    if (window.location.href.indexOf("/maps") > -1 && map2) {
      updateLayerLegend();
    }
  }, [layerMode]);

  useEffect(() => {
    if (window.location.href.indexOf("/maps") > -1 && map2) {
      updateLayers(false);
      updateLayerLegend();
    }
  }, [action]);

  useEffect(() => {
    if (window.location.href.indexOf("/maps") > -1 && map2) {
      if (map) {
        map.updateSize();
        map.setView(map2.getView());
      }
      if (map2) {
        map2.updateSize();
      }
    }
  }, [selectedMode]);

  useEffect(() => {
    if (window.location.href.indexOf("/maps") > -1 && map2) {
      updateLayers(
        map2.get("clicked") == 1 || map.get("clicked") == 1 ? false : true
      );
      map2.set("clicked", 0);
      map.set("clicked", 0);
      updateLayerLegend();
    }
  }, [fieldsState.locationsData]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        {fieldsState.fields.map((field_, index) => {
          return (
            <button
              className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm`}
              onClick={() => {
                if (field_.polygon_data && field_.polygon_data.length > 0) {
                  let geom_ = new GeoJSON().readGeometry(
                    field_.polygon_data[0].geom
                  );
                  let geomT = geom_.transform("EPSG:4326", "EPSG:3857");
                  map2.getView().fit(geomT.getExtent(), {
                    size: map2.getSize(),
                    maxZoom: 20,
                  });
                } else {
                  alert("Field Polygon does not exist");
                }
              }}
            >
              Zoom to {field_.title}
            </button>
          );
        })}
      </div>
      <div
        className={`pd5 ${
          selectedMode.toLowerCase() == "single" ? "" : "map-container-flex"
        }`}
      >
        <MapContext.Provider value={{ map }}>
          <div
            ref={mapRef}
            className={`${
              selectedMode == "Dual" ? "ol-map-half" : "map-hidden"
            }`}
          >
            {children}
          </div>
        </MapContext.Provider>
        <MapContext.Provider value={{ map2 }}>
          <div
            ref={mapRef2}
            className={
              selectedMode.toLowerCase() == "single" ? "ol-map" : "ol-map-half"
            }
          >
            {children}
          </div>
        </MapContext.Provider>
        <div id="popup-1" className="ol-popup">
          <div id="popup-content"></div>
        </div>
        <div id="popup-2" className="ol-popup">
          <div id="popup-content"></div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {selectedMode == "Dual" ? (
          <LayerLegend className="half-width" maxValue="240"></LayerLegend>
        ) : (
          ""
        )}
        <LayerLegend
          className={
            selectedMode.toLowerCase() == "single" ? "full-width" : "half-width"
          }
          maxValue={layerLegendColorMax2}
          minValue={layerLegendColorMin2}
          vector={vector2}
        ></LayerLegend>
        <GalleryLightBox images={selectedImages} open={photoOpen} setOpen={setPhotoOpen}/>
      </div>
    </div>
  );
};
export default Map;
