import React, { useEffect, useState } from "react";
import { Colors, Constants } from "../Utils";
import { Label } from "../Components";
import { useSelector, useDispatch } from "react-redux";
import { FieldServices } from "../services";
import useStorage from "../Hooks/useStorage";
import isEmpty from "../Utils/isEmpty";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, DialogContent } from "@mui/material";
import Select from "react-select";
import moment from "moment";
import Switch from "@mui/material/Switch";

function SideBar() {
  const dispatch = useDispatch();
  const fieldsState = useSelector((state) => state.Fields);
  const appSettingsState = useSelector((state) => state.AppSettings);
  const Insects = useSelector((state) => state.Insects);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [diseases, setDiseases] = useState([]);
  const selectedInsect = Insects.selected;
  const selectedDisease = Insects.selectedDisease;
  const selectedMode = appSettingsState.selectedMode;
  const action = appSettingsState.action;
  const photoMode = appSettingsState.photoMode;
  const insects = Insects.data;
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [storageInsect, setStorageInsect] = useStorage(
    "",
    Constants.SELECTED_INSECT_KEY
  );

  const getDates = async (startDate, endDate) => {
    if (isEmpty(selectedDisease)) {
      FieldServices.getDatesOfAField(
        fieldsState.uId,
        startDate,
        endDate,
        "<"
      ).then((res) => {
        let startDate = res.data?.start_date;
        let endDate = res.data?.end_date;
        dispatch.AppSettings.SetPreviousDates({
          startDate,
          endDate,
        });
      });
    } else {
      FieldServices.getPreviousDiseasesDates(
        fieldsState.uId,
        selectedDisease.value
      ).then((res) => {
        let startDate = res.data.start_date
          ? moment(res.data.start_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        let endDate = res.data.end_date
          ? moment(res.data.end_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        dispatch.AppSettings.SetPreviousDates({
          startDate,
          endDate,
        });
      });
    }
  };

  const getFilterData = async () => {
    try {
      var response = await FieldServices.getCitiesAndDistricts();
      if (response.data.data.cities) {
        setCities(response.data.data.cities);
      }
      if (response.data.data.districts) {
        setDistricts(response.data.data.districts);
      }

      response = await FieldServices.getUserCompanies();
      if (response.data.companies) {
        setCompanies(response.data.companies);
        const objUser = JSON.parse(localStorage.getItem("user"));
        if (
          response.data.companies.length > 0 &&
          objUser &&
          objUser.user_type != "advisor"
        ) {
          dispatch.Fields.setFilterData({
            companies: {
              value: response.data.companies[0].id,
              label: response.data.companies[0].title,
            },
          });
        }
      }

      response = await FieldServices.getPlantDiseases();
      if (response.data.fields) {
        setDiseases(response.data.fields);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const generateString = (key, label) => {
    if (!Array.isArray(fieldsState.filter[key])) {
      return fieldsState.filter[key].label;
    }
    return fieldsState.filter[key].length > 0
      ? `${fieldsState.filter[key][0].label}${
          fieldsState.filter[key].length > 1
            ? `, +${fieldsState.filter[key].length - 1}`
            : ""
        }`
      : label;
  };

  useEffect(() => {
    const objUser = JSON.parse(localStorage.getItem("user"));
    setUser(objUser);
    dispatch.Fields.setFilterData(
      JSON.parse(localStorage.getItem("mapper_filter"))
    );
    getFilterData();
  }, []);

  return (
    <div
      className="pl-5 pt-5"
      style={{ background: Colors.PRIMARY, minHeight: "100vh", height: "100%" }}
    >
      {user && (
        <div className="mt-5">
          <label className="mt-5 text-white font-bold">
            Welcome {`${user.first_name} ${user.last_name}`}
          </label>
        </div>
      )}
      <div className="mt-5">
        <Label
          text={"Single View"}
          onClick={() => {
            dispatch.AppSettings.SelectMode("single");
            // dispatch.Fields.ResetDualData();
          }}
          selected={selectedMode === "single" ? true : false}
        />{" "}
        |{" "}
        <Label
          text={"Multi View"}
          onClick={() => {
            dispatch.AppSettings.SelectMode("Dual");
            getDates(
              appSettingsState?.dates?.nextDate?.startDate,
              appSettingsState?.dates?.nextDate?.endDate
            );
          }}
          selected={selectedMode === "Dual" ? true : false}
        />
      </div>
      <div className="filter-block">
        {user && (
          <>
            {" "}
            {(user.user_type == "advisor" ||
              fieldsState?.filter?.cities.length > 0) && (
              <Label
                text={generateString("cities", "City")}
                onClick={() => {
                  setShow(true);
                }}
              />
            )}
            {(user.user_type == "advisor" ||
              fieldsState?.filter?.districts.length > 0) && (
              <Label
                text={generateString("districts", "District")}
                onClick={() => {
                  setShow(true);
                }}
              />
            )}
          </>
        )}
        <Label
          text={generateString("companies", "Company")}
          onClick={() => {
            setShow(true);
          }}
        />

        <Label
          text={
            !isEmpty(selectedInsect)
              ? selectedInsect
              : !isEmpty(selectedDisease)
              ? selectedDisease.label
              : "Organism"
          }
          onClick={() => {
            setShow(true);
          }}
        />
      </div>
      {/* <div className="mt-5">
        <label className="mt-5 text-white font-bold underline">
          Select Insect
        </label>
      </div>
      <div className="mt-2">
        {insects?.map((text, id) => (
          <div key={id}>
            <Label
              text={text}
              key={`${id}-insect`}
              selected={text === selectedInsect ? true : false}
              onClick={() => {
                dispatch.Insects.Select(text);
                setStorageInsect(text);
              }}
            />
            <br />
          </div>
        ))}
      </div> */}
      <div className="mt-5 sidebar-btn">
        <button
          className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary${
            action === Constants.SUM
              ? " text-lg text-white font-bold"
              : " text-sm"
          }`}
          onClick={() => {
            dispatch.AppSettings.SetAction(Constants.SUM);
          }}
        >
          Sum
        </button>

        <button
          className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary${
            action === Constants.AVG
              ? " text-lg text-white font-bold"
              : " text-sm"
          }`}
          onClick={() => {
            dispatch.AppSettings.SetAction(Constants.AVG);
          }}
        >
          Avg
        </button>
        <button
          className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary${
            action === Constants.RATE
              ? " text-lg text-white font-bold"
              : " text-sm"
          }`}
          onClick={() => {
            dispatch.AppSettings.SetAction(Constants.RATE);
          }}
        >
          Rate
        </button>
      </div>
      {window.location.href.indexOf("/maps") > -1 ? (
        <div className="mt-5 sidebar-btn">
          <button
            className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm`}
            onClick={() => {
              dispatch.AppSettings.SetZoom(Math.random());
            }}
          >
            Zoom
          </button>
          <button
            className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary text-sm`}
            onClick={() => {
              dispatch.AppSettings.SetLayerMode("color");
            }}
          >
            Color
          </button>
          <button
            className={`bg-transparent border-2 rounded-md mr-2 p-2 border-primary ${
              photoMode ? " text-lg text-white font-bold" : " text-sm"
            }`}
            onClick={() => {
              dispatch.AppSettings.TogglePhotoMode();
            }}
          >
            Photo
          </button>
        </div>
      ) : (
        ""
      )}

      <Dialog
        onClose={() => {
          setShow(false);
        }}
        open={show}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Select Filter</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <label>City</label>
            <Select
              className="single-select"
              classNamePrefix="select"
              name="city"
              menuPosition="fixed"
              value={fieldsState?.filter?.cities || []}
              onChange={(val) =>
                dispatch.Fields.setFilterData({
                  cities: val,
                })
              }
              isMulti
              options={cities?.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
            />
          </div>
          <div className="form-group">
            <label>District</label>
            <Select
              className="single-select"
              classNamePrefix="select"
              name="district"
              menuPosition="fixed"
              value={fieldsState?.filter?.districts || []}
              onChange={(val) =>
                dispatch.Fields.setFilterData({
                  districts: val,
                })
              }
              isMulti
              options={districts?.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
            />
          </div>
          <div className="form-group">
            <label>Company</label>
            <Select
              className="single-select"
              classNamePrefix="select"
              name="color"
              value={fieldsState?.filter?.companies || []}
              isMulti={user && user.user_type == "advisor"}
              onChange={(val) =>
                dispatch.Fields.setFilterData({
                  companies: val,
                })
              }
              menuPosition="fixed"
              options={companies?.map((item) => {
                return {
                  value: item.id,
                  label: item.title,
                };
              })}
            />
          </div>
          <div className="form-group">
            <label>Insects</label>
            <Select
              className="single-select"
              classNamePrefix="select"
              name="insect"
              value={selectedInsect ? { label: selectedInsect } : null}
              onChange={(val) => dispatch.Insects.Select(val.label)}
              menuPosition="fixed"
              options={insects?.map((text) => {
                return { value: text, label: text };
              })}
            />
          </div>
          <div className="form-group">
            <label>Diseases</label>
            <Select
              className="single-select"
              classNamePrefix="select"
              name="color"
              menuPosition="fixed"
              value={selectedDisease}
              onChange={(val) => dispatch.Insects.SelectDisease(val)}
              options={diseases?.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
            />
          </div>
          {selectedDisease && (
            <div className="form-group">
              <label>Show all disease photos</label>
              <Switch
                checked={fieldsState.filter.allDiseasePhoto}
                onChange={(event) =>
                  dispatch.Fields.setFilterData({
                    allDiseasePhoto: event.target.checked,
                  })
                }
              />
            </div>
          )}

          <div className="btn-group">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                dispatch.Fields.clearFilterData();
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setShow(false);
                dispatch.Fields.setApplyFilter(true);
              }}
            >
              ok
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SideBar;
