const Fields = {
  state: {
    selected: null,
    fieldDataName: "",
    fieldName: "",
    uId: "",
    status: "",
    changeRate: 0,
    fields: [],
    fieldsData: [],
    districtColors: [],
    locationsData: {
      single: [],
      dual: [],
    },
    mapsLocationsData: {
      single: [],
      dual: [],
    },
    selectedData: {
      single: [],
      dual: [],
    },
    timeLineData: {
      single: [],
      dual: [],
    },
    chartData: {
      single: [],
      dual: [],
    },
    plantImages: {
      single: {},
      daul: {}
    },
    filter: {
      cities: [],
      districts: [],
      companies: [],
      allDiseasePhoto: true
    },
    applyFilter: false,
  },
  reducers: {
    Select: (state, payload) => {
      return {
        ...state,
        selected: payload.id ,
        fieldName: payload.name,
        uId: payload.uId,
        status: payload.status,
      };
    },
    SetFields: (state, payload) => {
      return {
        ...state,
        applyFilter: false,
        fields: payload,
      };
    },
    SetColors: (state, payload) => {
      return {
        ...state,
        districtColors: payload,
      };
    },
    SetChangeRate: (state, payload) => {
      return {
        ...state,
        changeRate: payload,
      };
    },
    SetChartData: (state, payload) => {
      return {
        ...state,
        chartData: { ...state.chartData, [payload.type]: payload.data },
      };
    },
    SetPlantImages: (state, payload) => {
      return {
        ...state,
        plantImages: { ...state.plantImages, [payload.type]: payload.data },
      };
    },
    SetFieldDataName: (state, payload) => {
      return {
        ...state,
        fieldDataName: payload,
      };
    },
    SetTimeLineData: (state, payload) => {
      return {
        ...state,
        timeLineData: { ...state.timeLineData, [payload.type]: payload.data },
      };
    },
    AddFieldsData: (state, payload) => {
      let data = state.fieldsData;
      let index = data.findIndex((field) => field.uId === payload.uId);
      if (index >= 0) {
        data[index] = { ...data[index], ...payload };
      } else {
        data = [...data, payload];
      }
      return {
        ...state,
        fieldsData: [...data],
      };
    },
    AddLocationsData: (state, payload) => {
      if (payload.eraseData) {
        return {
          ...state,
          locationsData: {
            ...state.locationsData,
            [payload.type]: payload.data,
          },
          selectedData: {
            single: [],
            dual: [],
          },
        };
      } else {
        return {
          ...state,
          locationsData: {
            ...state.locationsData,
            [payload.type]: payload.data,
          },
        };
      }
    },
    AddSelectedData: (state, payload) => {
      let dataToEdit = state.selectedData[payload.type];
      let index = dataToEdit.findIndex((d) => d.id === payload.data.id);
      if (index === -1) {
        dataToEdit = [...dataToEdit, payload.data];
      } else {
        dataToEdit.splice(index, 1);
      }
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          [payload.type]: [...dataToEdit],
        },
      };
    },
    Reset: (state, payload) => {
      return {
        selected: null,
        fieldDataName: "",
        fieldName: "",
        uId: "",
        status: "",
        changeRate: 0,
        fields: [],
        fieldsData: [],
        districtColors: [],
        locationsData: {
          single: [],
          dual: [],
        },
        mapsLocationsData: {
          single: [],
          dual: [],
        },
        selectedData: {
          single: [],
          dual: [],
        },
        timeLineData: {
          single: [],
          dual: [],
        },
        chartData: {
          single: [],
          dual: [],
        },
        plantImages: {
          single: {},
          dual: {},
        },
        filter: {
          cities: [],
          districts: [],
          companies: [],
          allDiseasePhoto: true
        },
      };
    },
    ResetDualData: (state, payload) => {
      return {
        ...state,
        locationsData: {
          ...state.locationsData,
          dual: [],
        },
        mapsLocationsData: {
          ...state.mapsLocationsData,
          dual: [],
        },
        selectedData: {
          ...state.selectedData,
          dual: [],
        },
        timeLineData: {
          ...state.timeLineData,
          dual: [],
        },
        chartData: {
          ...state.chartData,
          dual: [],
        },
      };
    },
    setFilterData: (state, payload) => {
      const filter = {
        cities: payload?.cities ?? state.filter.cities,
        districts: payload?.districts ?? state.filter.districts,
        companies: payload?.companies ?? state.filter.companies,
        allDiseasePhoto: payload?.allDiseasePhoto ?? state.filter.allDiseasePhoto,
      };
      window.localStorage.setItem("mapper_filter", JSON.stringify(filter));
      return {
        ...state,
        filter: filter,
      };
    },
    clearFilterData: (state, payload) => {
      window.localStorage.removeItem("mapper_filter");
      return {
        ...state,
        filter: {
          cities: [],
          districts: [],
          companies: [],
        }
      };
    },
    setApplyFilter: (state, payload) => {
      return {
        ...state,
        applyFilter: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async addFieldData(data) {
      await new Promise((resolve) => setTimeout(() => resolve(), 100));
      dispatch.Fields.AddFieldsData(data);
    },
    async setChangeRate(data) {
      await new Promise((resolve) => setTimeout(() => resolve(), 100));
      dispatch.Fields.SetChangeRate(data);
    },
  }),
};
export default Fields;
